import React from "react"
import { StaticImage } from "gatsby-plugin-image";

import styled from "styled-components";
import Colors from '../../colors';

import FacebookLogo from '../../images/social/facebook.svg';
import TwitterLogo from '../../images/social/twitter.svg';
import LinkedinLogo from '../../images/social/linkedin.svg';

const StyledFooter = styled.footer`
  height: 98px;
  padding-left: 24px;
  padding-right: 24px;

  hr {
    width: 100%;
    background-color: #3C5358;
  }

  .footer__body {
    display: flex;
    justify-content: space-between;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.875rem;
    margin-top: 40px;
  }

  .footer__social {
    display: flex;
    flex-direction: row;

    @supports (gap: 14px) {
      gap: 14px;
    }

    @supports (not (gap: 14px)) {
      img {
        margin-right: 14px;
      }

      img:last-child {
        margin-right: 0;
      }
    }
  }

  .footer__social img {
    width: 22px;
    height: 22px;
  }
`;

const Footer = () => {
  return (
    <StyledFooter className='footer'>
      <hr />
      <div className='footer__body'>
        <span className='footer__copyright'>
          © Copyright {new Date().getFullYear() + 1}
        </span>
        <div className='footer__social'>
          <a href="https://twitter.com/aftermath_user">
            <img src={TwitterLogo} />
          </a>
          <a href="https://www.linkedin.com/company/aftermath/">
            <img src={LinkedinLogo} />
          </a>
        </div>
      </div>
    </StyledFooter>
  );
};

export default Footer;
