import React from "react"
import { Link } from 'react-head';
import PropTypes from "prop-types"
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import Colors from '../../colors';
import BorderlessLogo from '../../images/logo-borderless.svg';

const StyledFooter = styled.footer`
  padding-left: 24px;
  padding-right: 24px;
  background-color: #222222;
  display: flex;
  flex-direction: column;
  align-items: center;

  hr {
    background-color: #555555;
    width: 100%;
  }

  .footer__body {
    padding-top: 77px;
    padding-bottom: 40px;
    max-width: 1440px;
    width: 80%;
    display: flex;
    flex-direction: row;

    a {
      text-decoration: none;
      color: ${Colors.primary};
    }

    ul {
      list-style: none;
      margin: 0;
    }

    li {
      margin-bottom: 16px;
    }

    .borderless-logo {
      width: 142px;
      height: 20px;
    }

    .footer__logo {
      flex: 1 1 50%;
    }

    .footer__contact {
      flex: 1 1 auto;
      margin-left: 104px;
    }

    .footer__social {
      flex: 1 1 auto;
    }
  }

  .footer__copyright {
    padding-top: 40px;
    padding-bottom: 64px;
    text-align: center;
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <div className='footer__body'>
        <div className='footer__logo'>
          <img className='borderless-logo' alt='.aftermath logo' src={BorderlessLogo} />
        </div>
        <div className='footer__contact'>
          <h4>Contact us</h4>
          <ul>
            <li><a href='tel:+34 627 64 12 77'>ES +34 627 64 12 77</a></li>
            <li><a href='tel:+44 7961916494'>UK +44 7961916494</a></li>
            <li><a href='mailto:contact@after-math.es'>contact@after-math.es</a></li>
          </ul>
        </div>
        <div className='footer__social'>
          <h4>Social</h4>
          <ul>
            <li><a href="https://www.linkedin.com/company/aftermath/">LinkedIn</a></li>
            <li><a href="https://twitter.com/aftermath_user">Twitter</a></li>
          </ul>
        </div>
      </div>
      <hr/>
      <div className='footer__copyright'>
        © Copyright {new Date().getFullYear() + 1}
      </div>
    </StyledFooter>
  );
};

export default Footer;
