import React, { useState } from "react"
import styled from "styled-components";
import classNames from 'classnames';

import Colors from '../../colors';
import HamburguerToggle from '../../components/HamburguerToggle';
import BorderlessLogo from '../../images/logo-borderless.svg';
import BackgroundImage from '../../images/background.png';
import { Link } from "gatsby";

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 2;
  pointer-events: none;

  .header__head {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 114px;

    padding-bottom: 28px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 28px;

    border-bottom: 2px solid ${Colors.white};
    align-items: flex-end;
    touch-action: none;
    pointer-events: all;
  }

  .header__logo {
    width: 143px;
    height: 20px;
  }

  .header__navigation {
    flex: 1 0 auto;
    transform: scaleY(0);
    transition: transform .2s;
    transform-origin: top center;
    padding: 0 10%;
    padding-top: 62px;
    touch-action: none;
    pointer-events: all;

    &.expanded {
      transform: scaleY(1);
    }

    ul {
      list-style: none;
      margin: 0;
    }

    li {
      font-size: 1.125rem;
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      margin: 0;
      padding: 14px 0;
    }

    li:not(:last-child) {
      border-bottom: 1px solid #228694;
    }

    a {
      text-decoration: none;
      color: ${Colors.primary};
    }
  }

  @media screen and (max-width: 768px) {
    .header__head {
      padding-left: 10%;
      padding-right: 10%;
    }
  }

  @supports (backdrop-filter: blur(12px)) {
    .header__head {
      background: rgba(58, 88, 95, 0.01);
      backdrop-filter: blur(12px);
    }

    .header__navigation {
      background: rgba(58, 88, 95, 0.01);
      backdrop-filter: blur(12px);
    }
  }

  @supports (not (backdrop-filter: blur(12px))) {
    .header__head {
      position: relative;
    }

    .header__head:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      background-image: url(${ BackgroundImage });
      background-position: center center;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: cover;
      background-color: #2F585E;

      content: '';
      z-index: -1;
    }

    .header__navigation {
      background-color: #006978;
    }
  }
`;

const Header = () => {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  return (
    <StyledHeader className='header'>
      <div className='header__head'>
        <div className='header__logo'>
          <Link to='/'>
            <img src={BorderlessLogo} alt='.aftermath logo' />
          </Link>
        </div>
        <div className='header__toggle'>
          <HamburguerToggle
              isExpanded={isMenuExpanded}
              onClick={() => setIsMenuExpanded(!isMenuExpanded)}
            />
        </div>
      </div>
      <div className={classNames(
        'header__navigation',
        { 'expanded': isMenuExpanded }
      )}>
        <ul>
          <li><Link to='/expertise'>Expertise</Link></li>
          <li><Link to='/endorsements'>Endorsements</Link></li>
          <li><Link to='/research'>Research</Link></li>
          <li><Link to='https://getreplica.io/'>Replica</Link></li>
        </ul>
      </div>
    </StyledHeader>
  )
};

export default Header;
