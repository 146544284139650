import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import logo from '../images/logo.svg';
import BackgroundImage from '../images/background.png';
import Colors from '../colors';
import classNames from 'classnames';


const StyledNavbar = styled.header`
  display: flex;
  flex-direction: row;

  @supports (not (backdrop-filter: blur(12px))) {
    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      background-image: url(${ BackgroundImage });
      background-position: center center;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: cover;
      background-color: ${ Colors.background };

      content: '';
      z-index: -1;
    }
  }

  @supports (backdrop-filter: blur(12px)) {
    background: rgba(58, 88, 95, 0.01);
    backdrop-filter: blur(12px);
  }
  .navbar__left-section {
    flex: 0 1 9.2%;
    border-bottom: 2px solid white;
  }
`;

const LogoContainer = styled.div`
  width: 190px;
  flex: 0 0 200px;

  img {
    width: 190px;
    position: relative;
    margin: 0 5px;
    top: 20px;
  }
`;

const NavbarElements = styled.div`
  flex: 1 1 76.875%;
  border-bottom: 2px solid white;
  display: flex;
  justify-content: center;
  padding: 0 150px;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    list-style: none;
    width: 80%;
    height: 100%;
    margin: 0;
  }

  li {
    padding-bottom: 1.25rem;
    margin-bottom: 0;
    height: fit-content;

    a {
      color: white;
      text-decoration: none;
    }
  }
`

const Navbar = ({ isMenuOpen }) => {
  return (
    <StyledNavbar className={classNames(
      'navbar'
    )}>
      <div className='navbar__left-section' />
      <LogoContainer>
        <Link to='/'>
          <img src={ logo } alt='.aftermath logo' />
        </Link>
      </LogoContainer>
      <NavbarElements>
        <ul>
          <li><Link to='/expertise'>Expertise</Link></li>
          <li><Link to='/endorsements'>Endorsements</Link></li>
          <li><Link to='/research'>Research</Link></li>
          <li><Link to='https://getreplica.io/'>Replica</Link></li>
        </ul>
      </NavbarElements>
    </StyledNavbar>
  );
};

export default Navbar;
