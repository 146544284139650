const Colors = {
  primary: '#F5F5F5',
  secondary: '#D57C03',
  on:{
    primary: '#F4F4F4',
    secondary: '#2F585E'
  },
  background: '#2F585E',
};

export default Colors;
