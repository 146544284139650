import React, { useState } from "react";
import styled from "styled-components";
import classNames from 'classnames';

const StyledHamburguer = styled.button`
	border: none;
	border-radius: 0;
	color: #fff;
  background-color: transparent;
  cursor: pointer;
	display: inline-block;
	text-transform: uppercase;
	transition: all 0.25s ease-in-out;
  font-size: 0.875em;
  padding: 0;

  &:focus {
  	outline: thin dotted;
    outline-offset: -2px;
  }

  .icon {
  	display: inline-block;
  	fill: currentColor;
  	height: 1em;
  	width: 1em;
  	vertical-align: middle;
  	position: relative; /* Align more nicely with capital letters */
  	top: -0.0625em;
  }

  /* Menu toggle styles. */

  .icon-menu-toggle {
  	width: 24px;
  	height: 2em;
    top: 0;
  }

  /* Animate menu icon (lines). */
  .svg-menu-toggle .line {
    opacity: 1;
    transform: rotate(0) translateY(0) translateX(0);
    transform-origin: 1em 1em;
    transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;
  }

  .svg-menu-toggle .line-1 {
    transform-origin: 8px 2px;
  }

  .svg-menu-toggle .line-3 {
    transform-origin: 7px 7px;
  }

  &.expanded .svg-menu-toggle .line-1 {
    transform: rotate(45deg) translateY(0) translateX(0);
  }

  &.expanded .svg-menu-toggle .line-3 {
    transform: rotate(-45deg) translateY(0em) translateX(0em);
  }

  .screen-reader-text {
  	clip: rect(1px, 1px, 1px, 1px);
  	position: absolute !important;
  	height: 1px;
  	width: 1px;
  	overflow: hidden;
  }
`;

interface Props {
  onClick: () => any;
  isExpanded: boolean;
}

const HamburguerToggle = ({ onClick, isExpanded }: Props) => {
  return (
    <StyledHamburguer
        aria-expanded={ isExpanded }
				aria-label='Hamburguer Menu'
        className={classNames(
          'hamburguer-menu',
          { 'expanded': isExpanded }
        )}
        onClick={ onClick }
      >
      <svg className="icon icon-menu-toggle" x="0px" y="0px" viewBox="0 0 24 9">
  			<g className="svg-menu-toggle">
          <rect className='line line-1' width="24" height="1.5" fill="white"/>
          <rect className='line line-3' y="7.5" width="24" height="1.5" fill="white"/>
  			</g>
  		</svg>
    </StyledHamburguer>
  )
};

export default HamburguerToggle;
