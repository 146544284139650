import React from "react"
import { Link } from 'react-head';
import PropTypes from "prop-types"
import styled from 'styled-components';

import Header from "./Header"
import Footer from "./Footer";

import Colors from '../colors';
import BorderlessLogo from '../images/logo-borderless.svg';

const StyledLayout = styled.div`
  display: block;

  main {
    padding-top: 100px;
    padding-bottom: 140px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Layout = ({ children }) => {
  return (
    <StyledLayout>
      <Header siteTitle=''/>
      <main>{children}</main>
      <Footer />
    </StyledLayout>
  )

};

export default Layout;
