/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Link } from 'react-head';
import PropTypes from "prop-types"
import styled from 'styled-components';

import Colors from '../colors';
import background from '../images/background.png';
import DesktopLayout from '../containers/desktop/Layout';
import MobileLayout from '../containers/mobile/Layout';
import "./layout.css"

const Wrapper = styled.div`
  position: relative;

  &:before {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    background-image: url(${ background });
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: ${ Colors.background };

    content: '';
    z-index: -1;
  }

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    background-color: ${ Colors.background };

    content: '';
    z-index: -2;
  }
`;

const Layout = ({ children }) => {
  return (
    <Wrapper>
      <Link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap"
        rel="stylesheet"
      />
      <div>
        <DesktopLayout>{children}</DesktopLayout>
        <MobileLayout>{children}</MobileLayout>
      </div>
    </Wrapper>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
