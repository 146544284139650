import React from "react"
import styled from "styled-components";

import Header from "./Header";
import Footer from "./Footer";

const StyledLayout = styled.div`
  display: none;

  main {
    min-height: calc(100vh - 114px - 98px);
    z-index: 1;
  }

  @media screen and (max-width: 768px) {
    display: block;

    main {
      margin-top: 114px;
    }
  }
`;

const Layout = ({ children }) => {
  return (
    <StyledLayout>
      <Header />
      <main>{children}</main>
      <Footer />
    </StyledLayout>
  )
};

export default Layout;
