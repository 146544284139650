import React, { useContext } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import Navbar from '../../components/navbar';

const StyledHeader = styled.div`
  position: fixed;
  width: 100%;
  height: 100px;
  z-index: 99;

  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    content: '';
    z-index: -1;
  }
`;

interface HeaderProps {
  siteTitle: string;
}

const Header = ({ siteTitle }: HeaderProps) => {
  return (
    <StyledHeader className={classNames(
        'header'
    )}>
      <div className='header__navbar'>
        <Navbar />
      </div>
    </StyledHeader>
  )
}

export default Header;
